import { Carbon,
  CarbonLabel,
  New,
  ProductFeatureItem,
  ProductFeaturesList,
} from './product-features.styled';

interface ProductFeaturesProps {
  features?: string[];
  isNew?: boolean;
  newLabel?: string;
  co2Label?: string;
  showCo2Label?: boolean;
  absolute?: boolean;
  details?: boolean;
  style?: React.CSSProperties;
}

export const ProductFeatures: React.FC<ProductFeaturesProps> = ({
  features = ['PROF'],
  style,
  isNew,
  co2Label,
  showCo2Label,
  newLabel,
  details,
  absolute,
}) => {
  function getMachineName(name: string): string {
    return name?.toLowerCase().replace('+', '-plus');
  }

  function featureMapper(feature: string): React.ReactNode {
    const variant = getMachineName(feature);
    const key = `feature-${variant}`;
    return (
      <ProductFeatureItem key={key} $variant={variant}>
        {feature}
      </ProductFeatureItem>
    );
  }

  return (
    <ProductFeaturesList $absolute={absolute} style={style}>
      {showCo2Label && co2Label
      && (
        <Carbon>

          <CarbonLabel>
            {co2Label}
          </CarbonLabel>
        </Carbon>
      )}
      {isNew && <New>{newLabel}</New>}
      {features.filter(Boolean).map(featureMapper)}
    </ProductFeaturesList>
  );
};
